import { all, call, put, takeLatest, select } from "redux-saga/effects";
import {
  getStudentDashboardDataSuccess,
  getViewAllAssignmentsDataSuccess,
  getAssignmentResponseDataSuccess,
  getOtherStudentsTrackingDataSuccess,
  getTrackLessonDataSuccess,
  getTrackLessonResourceDataSuccess,
  getQuizDetailsDataSuccess,
  getStartQuizDataSuccess,
  getSubmitQuizDataSuccess,
  getStartLearnosityAssessmentSuccess,
  submitLearnosityAssessmentSuccess,
  getAssignmentFromLessonUrlSuccess,
  getCheckResourceCompletionSuccess,
} from "../actions/studentdashboardActions";
import {
  startSpinner,
  stopSpinner,
  showPopUp,
} from "../../../redux/authActions";
import * as actions from "../actions/actionTypes";
import { doGet, doPost, doGetSearch } from "../../../utils/ajaxHelper";
import { url } from "../../../utils/apiEndpoints";
import { act } from "react-test-renderer";
const _ = require("lodash");

export function* getStudentDashboard(action) {
  try {
    yield put(startSpinner());
    const fetchURL = `${url.dashboard.studentDashboard}?user_puid=${action.payload.user_Puid}`;
    const StudentDashboardData = yield call(doGet, fetchURL);
    if ([401, "401"].includes(StudentDashboardData?.status)) {
      yield put(showPopUp(401));
    } else
      yield put(
        getStudentDashboardDataSuccess(StudentDashboardData?.data?.data)
      );
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getStudentDashboardDataSuccess(null));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}

export function* getViewAllAssignment(action) {
  try {
    yield put(startSpinner());
    const fetchURL = `${url.dashboard.viewAllAssignments}?user_puid=${action.payload.user_Puid}&typeId=${action.payload.typeId}`;
    const viewAllAssignmentsData = yield call(doGet, fetchURL);
    if ([401, "401"].includes(viewAllAssignmentsData?.status)) {
      yield put(showPopUp(401));
    } else
      yield put(getViewAllAssignmentsDataSuccess(viewAllAssignmentsData?.data));
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getViewAllAssignmentsDataSuccess(null));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}

export function* getAssignmentResponses(action) {
  try {
    yield put(startSpinner());
    const fetchURL = `${url.dashboard.getAssignmentResponses}?user_puid=${action.payload.user_Puid}&lesson_id=${action.payload.lesson_id}&assignment_id=${action.payload.assignment_id}`;
    const GetAssignmentResponse = yield call(doGet, fetchURL);
    if ([401, "401"].includes(GetAssignmentResponse?.status)) {
      yield put(showPopUp(401));
    } else
      yield put(getAssignmentResponseDataSuccess(GetAssignmentResponse?.data));
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getAssignmentResponseDataSuccess(null));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}

export function* getStudentsTracking(action) {
  try {
    yield put(startSpinner());
    const fetchURL = `${url.dashboard.getStudentsTracking}?lessonId=${action.payload.lessonId}&user_puid=${action.payload.user_Puid}`;
    const GetStudentsTracking = yield call(doGet, fetchURL);
    if ([401, "401"].includes(GetStudentsTracking?.status)) {
      yield put(showPopUp(401));
    } else
      yield put(getOtherStudentsTrackingDataSuccess(GetStudentsTracking?.data));
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getOtherStudentsTrackingDataSuccess(null));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}

export function* getTrackLesson(action) {
  console.log("action", action);
  try {
    yield put(startSpinner());
    const fetchURL = `${url.dashboard.trackLesson}?user_puid=${action.payload.user_puid}&lesson_id=${action.payload.lesson_id}&assignment_id=${action.payload.assignment_id}&time_spent=${action.payload.time_spent}&tracking_id=${action.payload.tracking_id}&mode=${action.payload.mode}`;
    const getTrackLessonData = yield call(doPost, fetchURL);
    console.log("firstttt", getTrackLessonData?.data);
    if ([401, "401"].includes(getTrackLessonData?.status)) {
      yield put(showPopUp(401));
    } else yield put(getTrackLessonDataSuccess(getTrackLessonData?.data));
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getTrackLessonDataSuccess(null));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}

export function* getTrackLessonResource(action) {
  try {
    yield put(startSpinner());
    const fetchURL = `${url.dashboard.trackLessonResource}?user_puid=${action.payload.user_puid}&time_spent=${action.payload.time_spent}&percentage_completed=${action.payload.percentage_completed}&previous_resource_id=${action.payload.previous_resource_id}&resource_id=${action.payload.resource_id}&tracking_id=${action.payload.tracking_id}&lesson_tracking_id=${action.payload.lesson_tracking_id}&lesson_id=${action.payload.lesson_id}&resource_type_id=${action.payload.resource_type_id}&assignment_id=${action.payload.assignment_id}`;
    const getTrackLessonResourceData = yield call(doPost, fetchURL);
    console.log(
      "check kro bhai",
      getTrackLessonResourceDataSuccess(getTrackLessonResourceData?.data)
    );
    if ([401, "401"].includes(getTrackLessonResourceData?.status)) {
      yield put(showPopUp(401));
    } else
      yield put(
        getTrackLessonResourceDataSuccess(getTrackLessonResourceData?.data)
      );
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getTrackLessonResourceDataSuccess(null));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}

export function* getQuizDetails(action) {
  try {
    yield put(startSpinner());
    const fetchURL = `${
      action.payload.isAssessment
        ? url.assessment.assessmentOverview +
          `?user_puid=${action.payload.user_puid}&lesson_resource_tracking_id=${
            action.payload.lesson_resource_tracking_id
          }&lesson_id=${action.payload.lesson_id}&${
            action.payload.isAssessment ? "assessment_id=" : "quiz_id="
          }${
            action.payload.isAssessment
              ? action.payload.assessment_id
              : action.payload.quiz_id
          }`
        : url.dashboard.quizDetails +
          `?user_puid=${action.payload.user_puid}&lesson_resource_tracking_id=${action.payload.lesson_resource_tracking_id}&lesson_id=${action.payload.lesson_id}&quiz_id=${action.payload.quiz_id}`
    }`;

    // const fetchURL = `${
    //   action.payload.isAssessment
    //     ? url.assessment.assessmentOverview
    //     : url.dashboard.quizDetails
    // }?user_puid=${action.payload.user_puid}&lesson_resource_tracking_id=${
    //   action.payload.lesson_resource_tracking_id
    // }&lesson_id=${
    //   action.payload.lesson_id
    // }&`${action.payload.isAssessment}` ? assessment_id : quiz_id=${
    //   action.payload.quiz_id
    // }`;
    const getQuizDetailsData = yield call(doGet, fetchURL);
    if ([401, "401"].includes(getQuizDetailsData?.status)) {
      yield put(showPopUp(401));
    } else yield put(getQuizDetailsDataSuccess(getQuizDetailsData?.data));
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getQuizDetailsDataSuccess(null));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}

export function* getStartQuiz(action) {
  try {
    yield put(startSpinner());
    // const fetchURL = `${url.dashboard.startQuiz}?quiz_id=${action.payload.quiz_id}&user_puid=${action.payload.user_puid}&lesson_resource_tracking_id=${action.payload.lesson_resource_tracking_id}&lesson_id=${action.payload.lesson_id}`;
    const fetchURL = `${
      action.payload.isAssessment
        ? url.assessment.startAssessment +
          `?user_puid=${action.payload.user_puid}&lesson_resource_tracking_id=${
            action.payload.lesson_resource_tracking_id
          }&is_learnosity_enabled=${false}&lesson_id=${
            action.payload.lesson_id
          }&${action.payload.isAssessment ? "assessment_id=" : "quiz_id="}${
            action.payload.isAssessment
              ? action.payload.assessment_id
              : action.payload.quiz_id
          }`
        : url.dashboard.startQuiz +
          `?quiz_id=${action.payload.quiz_id}&user_puid=${action.payload.user_puid}&lesson_resource_tracking_id=${action.payload.lesson_resource_tracking_id}&lesson_id=${action.payload.lesson_id}`
    }`;
    const getStartQuizData = yield call(doPost, fetchURL);
    if ([401, "401"].includes(getStartQuizData?.status)) {
      yield put(showPopUp(401));
    } else yield put(getStartQuizDataSuccess(getStartQuizData?.data));
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getStartQuizDataSuccess(null));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}

export function* getSubmitQuiz(action) {
  try {
    yield put(startSpinner());
    const fetchURL = action.payload.isAssessment
      ? url.assessment.submitAssessment
      : url.dashboard.submitQuiz;
    const getSubmitQuizData = yield call(
      doPost,
      fetchURL,
      _.omit(action.payload, "isAssessment")
    );
    if ([401, "401"].includes(getSubmitQuizData?.status)) {
      yield put(showPopUp(401));
    } else yield put(getSubmitQuizDataSuccess(getSubmitQuizData?.data));
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getSubmitQuizDataSuccess(null));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}

export function* getStartAssessmentData(action) {
  try {
    yield put(startSpinner());
    const fetchURL = url.dashboard.startLearnosityAssessment;
    const startAssessmentData = yield call(doPost, fetchURL, action.payload);
    if ([401, "401"].includes(startAssessmentData?.status)) {
      yield put(showPopUp(401));
    } else
      yield put(getStartLearnosityAssessmentSuccess(startAssessmentData?.data));
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getStartLearnosityAssessmentSuccess(null));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}

export function* submitLearnosityAssessment(action) {
  try {
    yield put(startSpinner());
    const fetchURL = url.dashboard.submitLearnosityAssessment;
    const startAssessmentData = yield call(doPost, fetchURL, action.payload);
    if ([401, "401"].includes(startAssessmentData?.status)) {
      yield put(showPopUp(401));
    } else
      yield put(submitLearnosityAssessmentSuccess(startAssessmentData?.data));
  } catch (error) {
    yield put(showPopUp(501));
    yield put(submitLearnosityAssessmentSuccess(null));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}
export function* assignmentFromLessonUrl(action) {
  try {
    yield put(startSpinner());
    const fetchURL = `${url.dashboard.assignmentFromLessonUrl}?user_puid=${action.payload.user_puid}`;
    const AssignmentFromLessonUrlData = yield call(
      doPost,
      fetchURL,
      action.payload
    );
    if ([401, "401"].includes(AssignmentFromLessonUrlData?.status)) {
      yield put(showPopUp(401));
    } else
      yield put(
        getAssignmentFromLessonUrlSuccess(AssignmentFromLessonUrlData?.data)
      );
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getAssignmentFromLessonUrlSuccess(null));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}

export function* checkResourceCompletion(action) {
  try {
    yield put(startSpinner());
    const fetchURL = `${url.dashboard.checkResourceCompletion}?user_puid=${action.payload.user_puid}&resource_id=${action.payload.resource_id}&lesson_id=${action.payload.lesson_id}&resource_type_id=${action.payload.resource_type_id}&quiz_id=${action.payload.quiz_id}&isLearnosityEnabled=${action.payload.isLearnosityEnabled}`;
    const CheckResourceCompletionData = yield call(doPost, fetchURL);
    if ([401, "401"].includes(CheckResourceCompletionData?.status)) {
      yield put(showPopUp(401));
    } else
      yield put(
        getCheckResourceCompletionSuccess(CheckResourceCompletionData?.data)
      );
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getCheckResourceCompletionSuccess(null));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}

export const getProject = (state) => state.studentDashboard.GetTrackLessonData;
export function* invokeArrayOfSagas(action) {
  yield* getTrackLesson({ payload: action.payload[0] });
  let project = yield select(getProject);
  yield* getTrackLessonResource({
    payload: { ...action.payload[1], lesson_tracking_id: project?.data },
  });
}

export const AssignShareData = (state) =>
  state.studentDashboard.GetAssignmentFromLessonUrlData;
export function* getAssignShareResponseSaga(action) {
  yield* assignmentFromLessonUrl({ payload: action.payload[0] });
  let project = yield select(AssignShareData);
  yield* getAssignmentResponses({
    payload: {
      ...action.payload[1],
      lesson_id: project?.data?.lesson_id,
      assignment_id: project?.data?.assignment_id,
    },
  });
  yield* getStudentsTracking({
    payload: { ...action.payload[2], lessonId: project?.data?.lesson_id },
  });
}

export function* callGetStudentDashboard() {
  yield takeLatest(actions.GET_STUDENT_DASHBOARD, getStudentDashboard);
}
export function* callGetViewAllAssignment() {
  yield takeLatest(actions.GET_VIEW_All_ASSIGNMENTS, getViewAllAssignment);
}
export function* callGetAssignmentResponses() {
  yield takeLatest(actions.GET_ASSIGNMENT_RESPONSES, getAssignmentResponses);
}
export function* callGetStudentsTracking() {
  yield takeLatest(actions.GET_OTHER_STUDENTS_TRACKING, getStudentsTracking);
}
export function* callGetTrackLesson() {
  yield takeLatest(actions.GET_TRACK_LESSON, getTrackLesson);
}
export function* callGetTrackLessonResource() {
  yield takeLatest(actions.GET_TRACK_LESSON_RESOURCE, getTrackLessonResource);
}
export function* callGetQuizDetails() {
  yield takeLatest(actions.GET_QUIZ_DETAILS_DATA, getQuizDetails);
}
export function* callStartQuiz() {
  yield takeLatest(actions.GET_START_QUIZ_DATA, getStartQuiz);
}
export function* callSubmitQuiz() {
  yield takeLatest(actions.GET_SUBMIT_QUIZ_DATA, getSubmitQuiz);
}
export function* callGetStartAssessmentData() {
  yield takeLatest(
    actions.GET_START_LEARNOSITY_ASSESSMENT,
    getStartAssessmentData
  );
}
export function* callLaernositySubmitAssessment() {
  yield takeLatest(
    actions.SUBMIT_LEARNOSITY_ASSESSMENT,
    submitLearnosityAssessment
  );
}
export function* callinvokeArrayOfSagas() {
  yield takeLatest(actions.GET_TRACK_LESSON_AND_RESOURCE, invokeArrayOfSagas);
}
export function* callGetAssignShareResponseSaga() {
  yield takeLatest(
    actions.GET_TRACK_ASSIGN_SHARE_AND_RESPONSE,
    getAssignShareResponseSaga
  );
}
export function* callAssignmentFromLessonUrl() {
  yield takeLatest(
    actions.GET_ASSIGN_ASSIGNMENT_FROM_LESSONURL,
    assignmentFromLessonUrl
  );
}
export function* callCheckResourceCompletion() {
  yield takeLatest(
    actions.GET_CHECK_RESOURCE_COMPLETION,
    checkResourceCompletion
  );
}

export function* StudentdashboardSaga() {
  yield all([
    callGetStudentDashboard(),
    callGetViewAllAssignment(),
    callGetAssignmentResponses(),
    callGetStudentsTracking(),
    callGetTrackLesson(),
    callGetTrackLessonResource(),
    callGetQuizDetails(),
    callStartQuiz(),
    callSubmitQuiz(),
    callGetStartAssessmentData(),
    callLaernositySubmitAssessment(),
    callinvokeArrayOfSagas(),
    callAssignmentFromLessonUrl(),
    callCheckResourceCompletion(),
    callGetAssignShareResponseSaga(),
  ]);
}
