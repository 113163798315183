import * as actions from "./actionTypes";

export const getContentLibraryData = () => ({
  type: actions.GET_CONTENT_LIBRARY_DATA,
});
export const getContentLibrarySuccess = (payload) => ({
  type: actions.GET_CONTENT_LIBRARY_DATA_SUCCESS,
  payload: payload,
});
export const getUnitData = (payload) => ({
  type: actions.GET_UNIT_DATA,
  payload: payload,
});
export const getCorelationData = (payload) => ({
  type: actions.GET_CORELATION_DOCUMENT_DATA,
  payload: payload,
});

export const getCorelationDataSuccess = (payload) => ({
  type: actions.GET_CORELATION_DOCUMENT_DATA_SUCCESS,
  payload: payload,
});
export const getItemBreakoutList = (payload) => ({
  type: actions.GET_ITEM_BREAKOUT_LIST,
  payload: payload,
});
export const getItemBreakoutListSuccess = (payload) => ({
  type: actions.GET_ITEM_BREAKOUT_LIST_SUCCESS,
  payload: payload,
});
export const getGradesAndStandard = (payload) => ({
  type: actions.GET_GRADES_AND_STANDARDS,
  payload: payload,
});
export const getGradesAndStandardSuccess = (payload) => ({
  type: actions.GET_GRADES_AND_STANDARDS_SUCCESS,
  payload: payload,
});
export const getUnitDataSuccess = (payload) => ({
  type: actions.GET_UNIT_DATA_SUCCESS,
  payload: payload,
});
export const setUpdateFilter = (payload) => ({
  type: actions.SET_DEFAULT_FILTER,
  payload: payload,
});
export const setUpdateFilterSuccess = (payload) => ({
  type: actions.SET_DEFAULT_FILTER_SUCCESS,
  payload: payload,
});
export const getStaarPracticeTest = (payload) => ({
  type: actions.GET_STAAR_PRATICE_TEST,
  payload: payload,
});
export const getStaarPracticeTestSuccess = (payload) => ({
  type: actions.GET_STAAR_PRATICE_TEST_SUCCESS,
  payload: payload,
});
export const getItemData = (payload) => ({
  type: actions.GET_ITEMS_DATA,
  payload: payload,
});
export const getItemDataSuccess = (payload) => ({
  type: actions.GET_ITEMS_DATA_SUCCESS,
  payload: payload,
});
export const updateDefaultUnitData = (payload) => ({
  type: actions.UPDATE_DEFAULT_UNIT_DATA,
  payload: payload,
});
export const updateBreadCrumbsData = (payload) => ({
  type: actions.UPDATE_BREADCRUMBS_DATA,
  payload: payload,
});
export const updateSpinnerValue = (payload) => ({
  type: actions.UPDATE_SPINNER_VALUE,
  payload: payload,
});
export const getEngineeringDesingItems = (payload) => ({
  type: actions.GET_ENGINEERING_DESIGN_ITEM,
  payload: payload,
});
export const getEngineeringDesingItemsSuccess = (payload) => ({
  type: actions.GET_ENGINEERING_DESIGN_ITEM_SUCCESS,
  payload: payload,
});
export const setSelectedCorrelationData = (payload) => ({
  type: actions.SET_SELECTED_CORRELATION_DATA,
  payload: payload,
});
export const isCorelationURL = (payload) => ({
  type: actions.IS_CORRELATION_URL,
  payload: payload,
});
export const getSearchItem = (payload) => ({
  type: actions.GET_SEARCH_ITEM,
  payload: payload,
});
export const getSearchItemSuccess = (payload) => ({
  type: actions.GET_SEARCH_ITEM_SUCCESS,
  payload: payload,
});

export const setPreviewItemData = (payload) => ({
  type: actions.SET_PREVIEW_ITEM_DATA,
  payload: payload,
});
export const setPreviewItemDataSuccess = (payload) => ({
  type: actions.SET_PREVIEW_ITEM_DATA_SUCCESS,
  payload: payload,
});
export const setExternalResourceID = (payload) => ({
  type: actions.SET_EXTERNAL_RESOURCE_ID,
  payload: payload,
});

export const getMyLibraryData = (payload) => ({
  type: actions.GET_MYLIBRARY_DATA,
  payload: payload,
});

export const getMyLibraryDataSuccess = (payload) => ({
  type: actions.GET_MYLIBRARY_DATA_SUCCESS,
  payload: payload,
});
