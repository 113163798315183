export const GET_STUDENT_DASHBOARD = "GET_STUDENT_DASHBOARD";
export const GET_STUDENT_DASHBOARD_SUCCESS = "GET_STUDENT_DASHBOARD_SUCCESS";
export const GET_VIEW_All_ASSIGNMENTS = "GET_VIEW_All_ASSIGNMENTS";
export const GET_VIEW_All_ASSIGNMENTS_SUCCESS =
  "GET_VIEW_All_ASSIGNMENTS_SUCCESS";
export const GET_ASSIGNMENT_RESPONSES = "GET_ASSIGNMENT_RESPONSES";
export const GET_ASSIGNMENT_RESPONSES_SUCCESS =
  "GET_ASSIGNMENT_RESPONSES_SUCCESS";
export const GET_OTHER_STUDENTS_TRACKING = "GET_OTHER_STUDENTS_TRACKING";
export const GET_OTHER_STUDENTS_TRACKING_SUCCESS =
  "GET_OTHER_STUDENTS_TRACKING_SUCCESS";
export const GET_TRACK_LESSON = "GET_TRACK_LESSON";
export const GET_TRACK_LESSON_SUCCESS = "GET_TRACK_LESSON_SUCCESS";
export const GET_TRACK_LESSON_RESOURCE = "GET_TRACK_LESSON_RESOURCE";
export const GET_TRACK_LESSON_RESOURCE_SUCCESS =
  "GET_TRACK_LESSON_RESOURCE_SUCCESS";
export const GET_QUIZ_DETAILS_DATA = "GET_QUIZ_DETAILS_DATA";
export const GET_QUIZ_DETAILS_DATA_SUCCESS = "GET_QUIZ_DETAILS_DATA_SUCCESS";
export const GET_START_QUIZ_DATA = "GET_START_QUIZ_DATA";
export const GET_START_QUIZ_DATA_SUCCESS = "GET_START_QUIZ_DATA_SUCCESS";
export const GET_SUBMIT_QUIZ_DATA = "GET_SUBMIT_QUIZ_DATA";
export const GET_SUBMIT_QUIZ_DATA_SUCCESS = "GET_SUBMIT_QUIZ_DATA_SUCCESS";
export const GET_START_LEARNOSITY_ASSESSMENT =
  "GET_START_LEARNOSITY_ASSESSMENT";
export const GET_START_LEARNOSITY_ASSESSMENT_SUCCESS =
  "GET_START_LEARNOSITY_ASSESSMENT_SUCCESS";
export const SUBMIT_LEARNOSITY_ASSESSMENT = "SUBMIT_LEARNOSITY_ASSESSMENT";
export const SUBMIT_LEARNOSITY_ASSESSMENT_SUCCESS =
  "SUBMIT_LEARNOSITY_ASSESSMENT_SUCCESS";
export const GET_TRACK_LESSON_AND_RESOURCE = "GET_TRACK_LESSON_AND_RESOURCE"
export const  GET_ASSIGN_ASSIGNMENT_FROM_LESSONURL = "GET_ASSIGN_ASSIGNMENT_FROM_LESSONURL"
export const GET_ASSIGN_ASSIGNMENT_FROM_LESSONURL_SUCCESS =
  "GET_ASSIGN_ASSIGNMENT_FROM_LESSONURL_SUCCESS";
export const GET_CHECK_RESOURCE_COMPLETION = "GET_CHECK_RESOURCE_COMPLETION";
export const GET_CHECK_RESOURCE_COMPLETION_SUCCESS = "GET_CHECK_RESOURCE_COMPLETION_SUCCESS";
export const GET_TRACK_ASSIGN_SHARE_AND_RESPONSE= "GET_TRACK_ASSIGN_SHARE_AND_RESPONSE"
